import { classed } from '@tw-classed/react';
import NextLink from 'next/link';

export const Link = classed(NextLink, 'hover:underline', {
  variants: {
    color: {
      text: 'text-gray-120',
      textSecondary: 'text-gray-110',
      textTernary: 'text-gray-100',
      primary: 'text-sky-90',
    },
    size: {
      sm: 'text-sm font-light',
      md: 'text-base',
    },
  },
  defaultVariants: {
    color: 'text',
    size: 'md',
  },
});
