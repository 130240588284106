'use client';

/* eslint-disable no-console */
import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import { Button } from '~/components/ui/button';
import { logger } from '~/lib/logger/logger';
import { Link } from '../atoms/link/Link';
import { Typo } from '../ui/typography';

type Props = {
  children?: ReactNode;
  fallback?: ReactNode | ((error: string, resetError: () => void) => ReactNode);
};

type State = {
  hasError: boolean;
  error: string;
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    logger.info('ErrorBoundary: ', error.message);
    return { hasError: true, error: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public resetError = () => {
    this.setState({ hasError: false, error: '' });
  };

  // eslint-disable-next-line @typescript-eslint/promise-function-async
  public render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        if (typeof this.props.fallback === 'function')
          return this.props.fallback(this.state.error, this.resetError);

        return this.props.fallback;
      }
      return (
        <div className="flex flex-col gap-2">
          <Typo variant="h2">Error</Typo>
          <pre>{this.state.error}</pre>
          <Link href="https://docs.codeline.app">Documentation</Link>
          <Button
            variant="outline"
            onClick={() => {
              this.setState({ hasError: false });
            }}
          >
            Retry
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
